// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "@remix-run/react";
import { useEffect } from "react";
import Loader from "~/components/Loader";
export const meta = () => {
  return [{
    title: "WattShift"
  }, {
    name: "description",
    content: "Watthsift"
  }];
};
export default function Index() {
  _s();
  const navigate = useNavigate();
  const {
    isLoading,
    isAuthenticated
  } = useAuth0();
  useEffect(() => {
    if (isLoading) {
      return;
    }
    const userData = JSON.parse(localStorage.getItem("user_data") ?? "{}");
    if (isAuthenticated || userData?.access_token) {
      navigate("/user/user-status");
      return;
    }
    navigate("/user/login");
  }, [isAuthenticated, navigate, isLoading]);
  return <Loader loading={isLoading} />;
}
_s(Index, "vJ3ywncDDKa563Te8X4lkgiJTQE=", false, function () {
  return [useNavigate, useAuth0];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;